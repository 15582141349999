const { REACT_APP_ENV } = process.env;
const subConfig =
  REACT_APP_ENV === 'live'
    ? {
        appId: 'h5',
        secretKey: '9pqxnalum29g04ytzestplfyqpzeaewa',
        publicKey: '',
      }
    : {
        appId: 'h5',
        secretKey: '1orqni6646qf44iiol0tfk5pov1mml2z',
        publicKey: '',
      };

const envConfig = (() => {
  // mock, dev, test1
  if (['mock', 'dev', 'test1'].indexOf(REACT_APP_ENV) >= 0) {
    return {};
  }

  // live
  if (REACT_APP_ENV === 'live') {
    return {};
  }

  // test, uat
  return {};
})();

const config = {
  platForm: 1,
  version: 1,
  region: 'tw',
  insuranceVersion: 'insurance_h5_v1.0.0.100',
  // appVersion: bridge.checkAppVersion(),
  // rnVersion: bridge.checkRnVersion(),
  ...subConfig,
  ...envConfig,
};

export default config;
