import { isUndefined } from '@/common/util';
import { ICurrentQuery } from './types';

export function isH5Url(url: string) {
  return /^(http(s)?:)?\/\//.test(url);
}
export function isRnUrl(url: string) {
  return /^\/?(rn\/|n\/)/.test(url);
}
export type TCurrentQuery = keyof ICurrentQuery;

/**
 * 将对象转换成拼接 url 的参数 a=1&b=2
 * @param obj {a:1,b:2}
 * @returns  a=1&b=2
 */
export function parseObjectToQueryUrl(obj: Record<string, any>) {
  if (!obj) return '';
  const result = [];
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key))
      result.push(`${key}=${encodeURIComponent(obj[key])}`);
  }
  return result.join('&');
}
/**
 * 将查询 url 字符串转换成对象
 * @param str 将 a=1&b=2 转换成 {a:1,b:2}
 * @returns
 */
export function parseQueryUrlToObject(str: string) {
  const paramArray = str.replace(/[^?]*\?/, '').split('&');
  const obj: ICurrentQuery = {};

  paramArray.forEach((param: string) => {
    const [k, value] = param.split('=');
    if (typeof value != 'undefined' && value != 'undefined')
      // @ts-ignore
      obj[k as TCurrentQuery] = decodeURIComponent(value);
  });
  return obj;
}
let currentPageQuery: ICurrentQuery;

export function parseUrlQuery(): ICurrentQuery {
  if (currentPageQuery) return currentPageQuery;
  const { search } = window.location;
  if (!search) {
    return {};
  }

  currentPageQuery = parseQueryUrlToObject(search);
  // 兼容两个 policyId
  const policyId = currentPageQuery.policy_id || currentPageQuery.policyId;
  if (policyId) {
    currentPageQuery.policyId = policyId;
    currentPageQuery.policy_id = policyId;
  }
  return currentPageQuery;
}

export function getUrlParamByKey(key: TCurrentQuery) {
  return parseUrlQuery()[key] || '';
}
/**
 * 获取页面链接的参数，如果没有获取到则返回 undefined，这样在接口调用等会直接不传这个参数
 * @param key url参数
 * @returns string | undefined
 */
export function getUrlParamByKey2(key: TCurrentQuery) {
  return getUrlParamByKey(key) || undefined;
}
export function getUrlProductId() {
  return parseUrlQuery().product_id || '';
}
export function getUrlPolicyId() {
  return parseUrlQuery().policy_id || '';
}
export function getUrlClaimId() {
  return parseUrlQuery().claim_id || '';
}

const EMPTY_STRING = '';

/**
 * @param {string} url url to parse (url must be decoded before passing to this util)
 * 将 url 拆分成如下
 * host: string;
  hostname: string;
  pathname: string;
  hash: string;
  search: string;
  href: string;
  protocol: string;
  origin: string;
 */
interface Url {
  host: string;
  hostname: string;
  pathname: string;
  hash: string;
  search: string;
  href: string;
  protocol: string;
  origin: string;
}
export function parseUrlToObject(url: string): Url {
  if (!url)
    return {
      host: EMPTY_STRING,
      pathname: EMPTY_STRING,
      hostname: EMPTY_STRING,
      hash: EMPTY_STRING,
      search: EMPTY_STRING,
      href: EMPTY_STRING,
      protocol: EMPTY_STRING,
      origin: EMPTY_STRING,
    };
  url = url.trim();
  if (!isH5Url(url)) {
    const m = url.match(/^(rn)\/([^?]+)\??([^?#]*)#?([^#]*)/);
    if (m) {
      return {
        protocol: '',
        host: m[1],
        hostname: m[1],
        pathname: m[2],
        search: m[3],
        hash: m[4],
        href: url,
        origin: m[1],
      };
    }
  }
  /**
   * Need to try this "URL" API if available
   * because in worker context, only this API is available, DOM is not available
   * https://developer.mozilla.org/en-US/docs/Web/API/URL/URL
   * */
  if (window && window.URL) {
    try {
      const {
        host = EMPTY_STRING,
        pathname = EMPTY_STRING,
        hostname = EMPTY_STRING,
        hash = EMPTY_STRING,
        search = EMPTY_STRING,
        href = EMPTY_STRING,
        protocol = EMPTY_STRING,
        origin = EMPTY_STRING,
      } = new URL(url);
      if (!host || !hostname || !pathname) {
        throw new Error('Instance of URL returns wrong values, trying fallback method');
      }
      return {
        host,
        hostname,
        pathname,
        hash,
        search,
        href,
        protocol,
        origin,
      };
    } catch (e) {
      // no-op
    }
  }

  const elem = document.createElement('a');
  elem.href = url;

  // Reference: https://developer.mozilla.org/en-US/docs/Web/API/HTMLAnchorElement
  const { host, hostname, pathname, hash, search, origin, href, protocol } = elem;

  return {
    host,

    /**
     * [IE omits the leading slash in `elem.href.pathname`](https://stackoverflow.com/questions/956233/javascript-pathname-ie-quirk)
     */
    pathname: pathname !== '' && pathname[0] !== '/' ? `/${pathname}` : pathname,
    hostname,
    hash,
    search,
    href,
    protocol,

    /**
     * [`origin` not available in IE and Opera](https://developer.mozilla.org/en-US/docs/Web/API/HTMLHyperlinkElementUtils/origin)
     */
    origin: origin || `${protocol}://${host}`,
  };
}
/**
 * 新增或者替换参数，如果有就替换，没有就新增
 * @param key 新增或者替换 的 key
 * @param value 新增或者替换 的 value
 * @param url 需要替换的 url，默认是当前页面链接
 * @returns 返回 url 新增或者替换后的 url
 */
export function replaceParam(key: string, value: string, url = location.href) {
  const regex = new RegExp(`([?&#])${key}=[^&]*`, 'i');
  value = encodeURIComponent(value);

  if (url.match(regex)) {
    return url.replace(regex, value ? `$1${key}=${value}` : '$1').replace(/([?&])&/, '$1');
  }
  const separator = url.indexOf('?') > 0 ? '&' : '?';
  return `${url}${separator}${key}=${value}`;
}

interface ITransform {
  pathname?: string;
  search?: string | Record<string, string | number>;
  hash?: string | Record<string, string | number>;
  ins_level?: string | number;
}
// 禁止新增
// 禁止新增
// 禁止新增
const directTransfromQuery: (keyof ICurrentQuery)[] = [
  'entry',
  'product_id',
  'policy_id',
  'from',
  'debug',
  'road_cache_key',
];
/**
 * 组装新的跳转链接，默认是当前页面的 域名前缀
 * @param options url 的组装值 有： pathname,search,hash
 * 默认透传，会加上当前页面链接的：ins_level,entry, product_id, policy_id, from 四个参数，如 search 已存在已 search 的为准
 * @param host 默认当前 eg: insurance.shopee.co.id ,如果 host 这个传参传参是一个完整的 url ，则不转换，直接返回
 * @example
 * exe:  transformUrl({pathname:'/ins/checkout.html',search:{a:1,b:2},hash:{c:3,d:4}})
 * output: https://insurance.test.shopee.co.id/ins/checkout.html?a=1&b=2#c=3&d=4
 * exe:  transformUrl({pathname:'/ins/checkout.html'})
 * output: https://insurance.shopee.co.id/ins/checkout.html
 * exe:  transformUrl({pathname:'/ins/checkout.html',search:'a=1&b=2'})
 * output: https://insurance.test.shopee.co.id/ins/checkout.html?a=1&b=2
 * exe:  transformUrl({pathname:'/ins/checkout.html',search:{a:1,b:2}})
 * output: https://insurance.test.shopee.co.id/ins/checkout.html?a=1&b=2
 * exe:  transformUrl({pathname:'/ins/checkout.html',hash:{a:1,b:2}})
 * output: https://insurance.test.shopee.co.id/ins/checkout.html#a=1&b=2
 * exe:  transformUrl({pathname:'/ins/checkout.html',hash:'a=1&b=2'})
 * output: https://insurance.test.shopee.co.id/ins/checkout.html#a=1&b=2
 */
export function transformUrl(options: ITransform = {}, url: string = ''): string {
  let pathUrl: Partial<Url> = {};
  if (url) {
    if (/^\/(?!rn)[^/]/.test(url)) {
      url = `${location.protocol}//${location.host}${url}`;
    }
    pathUrl = parseUrlToObject(url);
  }

  const commonSearch: any = {};
  const query = parseUrlQuery();
  let inslevel: string | number = 0;
  if (!url || url.indexOf(location.host) >= 0) {
    if (!isUndefined(options.ins_level)) {
      inslevel = options.ins_level || 0;
    } else {
      inslevel = +(+query.ins_level! || 1) + 1;
    }

    commonSearch.ins_level = inslevel;
    if (!url.includes('/common/home.html')) {
      directTransfromQuery.forEach((key: keyof ICurrentQuery) => {
        if (key && !isUndefined(query[key])) {
          commonSearch[key] = query[key];
        }
      });
    }
  }
  let urlSearch: Record<string, any> = {};
  if (pathUrl.search) {
    urlSearch = parseQueryUrlToObject(pathUrl.search);
  }

  let optionSearch: Record<string, any>;
  if (typeof options.search == 'string') {
    optionSearch = parseQueryUrlToObject(options.search) || {};
  } else {
    optionSearch = (options.search as Object) || {};
  }

  const resultSearch = Object.assign(commonSearch, urlSearch, optionSearch);

  let hash = options.hash || '';
  if (typeof options.hash == 'object') {
    hash = parseObjectToQueryUrl(options.hash);
  }
  hash = (hash as string).replace(/#*/, '');

  let pathname = options.pathname || pathUrl.pathname || location.pathname;
  if (pathname) {
    pathname = pathname.replace(/(\/)$/, '');
  }
  // pathUrl.host 有可能是 rn 所以判断是否有值，rn不需要拼 location
  return `${
    pathUrl.host
      ? (pathUrl.protocol ? `${pathUrl.protocol}//` : '') + pathUrl.host
      : `${location.protocol}//${location.host}`
  }${pathname}?${parseObjectToQueryUrl(resultSearch)}${hash ? `#${hash}` : ''}`;
}

export function pushState(url: string) {
  try {
    // react-router/packages/router/history.ts
    // try...catch because iOS limits us to 100 pushState calls :
    history.pushState('', '', url);
  } catch (error) {
    window.location.assign(url);
  }
}

export function forceHttps(url: string) {
  if (url) {
    url = url.replace(/^((https?:)?\/\/)?/, 'https://');
  }
  return url;
}
