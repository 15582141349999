import { formatCurrency } from '@/common/biz';
import { isEmpty } from '@/common/util';
import { formatMsg } from '@/locales';
import { ProductTag } from '@/types/common';
import NP from 'number-precision';

export const getPromotionTagText = (params: {
  value?: string;
  tag_type:
    | 'cashback'
    | 'coinback'
    | 'discount'
    | 'flash_sale_discount'
    | 'family_discount'
    | 'voucher';
  value_type: 'Percentage' | 'Digital';
}) => {
  const { value, tag_type, value_type } = params || {};
  if (!value || !value_type || !tag_type) return '';
  const formatValue =
    value_type == 'Percentage' ? `${NP.times(value, 100)}%` : formatCurrency(+value);
  let result = '';
  switch (tag_type) {
    case 'cashback': {
      result = `${formatMsg('recommendedcard.cashbackbanner', { cashback: formatValue })}`;
      break;
    }
    case 'coinback': {
      result = `${formatMsg('recommendedcard.coinbackbanner', { coinback: formatValue })}`;
      break;
    }
    case 'family_discount':
      result = `${formatMsg('recommendedcard.familyDiscount', { familyDiscount: formatValue })}`;
      break;
    case 'flash_sale_discount':
    case 'discount':
      return formatValue;
    default:
  }
  return result;
};

export const parseProductTags = (product_tags?: ProductTag[]) => {
  if (!product_tags) return {};
  const periodTags: string[][] = [];
  const promotionTags: string[] = [];
  let installmentTag: string[] = [];
  let maskTag: string = '';
  let discountTag: string = '';
  let flashDiscountTag: string = '';
  let familyDiscountTag: string = '';
  let voucherTag: string = '';
  for (const tag of product_tags) {
    const { tag_type, tag_content_list = [] } = tag || {};
    if (!tag_type || isEmpty(tag_content_list)) continue;
    // eslint-disable-next-line no-loop-func
    tag_content_list.map((tag_content) => {
      const tagContent = tag_content?.item || [];
      switch (tag_type) {
        case 'flash_sale_discount':
          flashDiscountTag = getPromotionTagText({
            value: tagContent[0],
            tag_type,
            value_type: tagContent[1] as 'Percentage' | 'Digital',
          });
          break;
        case 'family_discount':
          familyDiscountTag = getPromotionTagText({
            value: tagContent[0],
            tag_type,
            value_type: tagContent[1] as 'Percentage' | 'Digital',
          });
        // eslint-disable-next-line no-fallthrough
        case 'discount':
          discountTag = getPromotionTagText({
            value: tagContent[0],
            tag_type: 'discount',
            value_type: tagContent[1] as 'Percentage' | 'Digital',
          });
          break;
        case 'cashback':
        case 'coinback':
          promotionTags.push(
            getPromotionTagText({
              value: tagContent[0],
              tag_type,
              value_type: tagContent[1] as 'Percentage' | 'Digital',
            }),
          );
          break;
        case 'period':
          periodTags.length < 3 && periodTags.push(tagContent);
          break;
        case 'installment':
          installmentTag = tagContent;
          break;
        case 'voucher':
          voucherTag = tagContent[0];
          break;
        case 'mark':
          maskTag = tagContent[0];
          break;
        default:
      }
    });
  }
  return {
    periodTags,
    promotionTags,
    installmentTag,
    maskTag,
    discountTag,
    voucherTag,
    flashDiscountTag,
    familyDiscountTag,
  };
};
