import { getUrlParamByKey } from '@/module/url';
import { setLocalItem, getLocalItem, removeItem, deleteByKey } from '@/module/storage';
import { get } from 'lodash-es';
import { getCookie } from '../cookie';
import { STORAGE_CACHE_KEY } from './constant';
import { IActivityInfo, IActivityInfoV2 } from '@/types/common';

export type ICarRoadCache = {
  pdp?: {
    // isReconditioned: boolean;
    // idType: string;
    // idNo: string;
    // postalCode: string;
  };
  quote?: {
    // [proppName: string | number]: any;
    // vehicleNo: {
    //   coverageType: string;
    //   carType: string;
    //   addOns: string;
    // };
  };
  comfirm?: {
    fullName: string;
    phoneNumber: string;
    emailAddress: string;
    state: string;
    detailAddress: string;
  };
  activity_info?: IActivityInfo | IActivityInfoV2[];
  activity_infos?: IActivityInfo | IActivityInfoV2[];
  createPolicyParams?:
    | {
        program_type?: string;
        policy_subject?: {
          [K in string]: any;
        } & {
          motor_info?: {
            [K in string]: any;
          } & {
            vehicle_detail?: {
              vehicle_info?: any;
              vehicle_policy?: any;
              vehicle_owner?: any;
            };
          };
        };
        order_items?: any;
        declaration_list?: {
          question_code: string;
          answer: string;
        }[];
      }
    | any;
  calcPremiums?: Record<string, any>;
  popupMessage?: {
    currentScene?: any;
  } & {
    [K in string]: any;
  };
} & {
  [K in string]: any;
};
let CAR_ROAD_CACHE_KEY = '';
export function getCacheKey(
  road_cache_key = '',
  prefix = STORAGE_CACHE_KEY.MY_CAR_CACHE_KEY,
  option?: Record<string, any>,
): string {
  if (CAR_ROAD_CACHE_KEY && (!option || !option.noNeedCache)) return CAR_ROAD_CACHE_KEY;
  let product_id = road_cache_key;
  if (!product_id) {
    product_id = getUrlParamByKey('road_cache_key');
    if (product_id == 'undefined') {
      product_id = '';
    }
  }
  if (!product_id) {
    product_id = getUrlParamByKey('product_id');
  }
  const userid = getCookie('userid') || getCookie('shopee_user_id');
  // 续保流程需要policy_id
  const policyId = option?.policy_id || getUrlParamByKey('policy_id');
  CAR_ROAD_CACHE_KEY = `${prefix}_${userid}_${product_id}${policyId ? `_${policyId}` : ''}`;
  // console.warn('cache_key', CAR_ROAD_CACHE_KEY);
  return CAR_ROAD_CACHE_KEY;
}
const roadCacheDefaultOpts = [
  {
    condition: (key: string) => key.includes('1672933236801558529'),
    opts: { expire: '60d' },
  },
];

/**
 *
 * @param value 设置缓存对象，对象采用 merge形式 ，使用 lodash.merge
 *  @param {number/string/datetime} options.expire 待转换的时间，有4种格式：
 *  @description option.expire
 *  @summary 1）参数为`yyyy/MM/dd h:m:s`格式的过时间字符串，绝对时间，兼容(yyyy-MM-dd h:m:s和yyyy.MM.dd h:m:s)；
 *  @summary 2）参数为日期对象，绝对时间；
 *  @summary 3）参数为数字，绝对时间，unix时间戳；
 *  @summary 4）格式为`\d+[smhd]`，其中s 表示秒、m 分钟、h 小时、d 天，如 30d，表示相当于当前时间的偏移，返回为当前时间+偏移的时间戳
 *  @summary option:{expire:'30d'};// 缓存 30天 有效
 * @example
 * setRoadCache({pdp:xxx},{expire:'24h'}) //缓存 24小时 有效
 * setRoadCache({pdp:xxx},{expire:1000}) //缓存 1000s 有效
 * setRoadCache({pdp:xxx},{expire:'30d',merge:false}) //缓存 30d 有效，用当前的 value 直接覆盖，不 merge
 * @example
 * setRoadCache({ pdp: {idType:1111}})  // 最终数据为 { pdp: { idType:1111}}
 * setRoadCache({ quote: { carType:2222}}) //{ pdp: { idType:1111} , quote: { carType:2222}}
 * setRoadCache({pdp:{idType:111,list:[1]}},(objValue,srcValue)=>{
 * // deep 到这里，业务的处理方式，可以 contact objValue,srcValue，或者覆盖
 * if (Array.isArray(objValue)) {
    return srcValue;
  }
 * })
 */
export function setRoadCache(
  value: ICarRoadCache,
  customizer?: Function,
  road_cache_key?: string,
  option?: Record<string, any>,
) {
  let customizerInner = customizer;
  if (typeof customizer == 'object') {
    option = customizer;
    customizerInner = undefined;
  }
  let merge = true;
  if (option && typeof option.merge == 'boolean') {
    merge = option.merge;
  }
  const roadCacheKey = option?.full_key || getCacheKey(road_cache_key, undefined, option);
  const defaultOpts = roadCacheDefaultOpts.find((i) => i.condition(roadCacheKey))?.opts;
  return setLocalItem(
    roadCacheKey,
    value,
    Object.assign({ merge, customizer: customizerInner, ...defaultOpts }, option || {}),
  );
}

/**
 * 默认只在第1层级mergeWith, 当level>1时覆盖式更新
 * 原缓存为 { a: { b: 1, c: { c1: {c3:6,c4:5}},h:8 }, d: 3,e:{a:2} };
 * setRoadCache2({ a: { b: 4, e: 5, c:{c1:{c2:99}} }, f: 6 }}); //最终数据为 { a: { b: 4, c: { c1: { c2: 99 } }, e: 5 }, d: 3, e: { a: 2 }, f: 6 }
 * setRoadCache2({ a: { b: 4, e: 5, c:{c1:{c2:99}} }, f: 6 },{level:1}); //最终数据为 { a: { b: 4, c: { c1: { c2: 99 } }, h: 8, e: 5 }, d: 3, e: { a: 2 }, f: 6 }
 * setRoadCache2({ a: { b: 4, e: 5, c:{c1:{c2:99}} }, f: 6 },{level:4}); //最终数据为 {
  a: { b: 4, c: { c1: { c3: 6, c4: 5, c2: 99 } }, h: 8, e: 5 },
  d: 3,
  e: { a: 2 },
  f: 6
}
 * @param value
 * @param customizer
 * @param road_cache_key
 * @param option
 * @returns
 */
export function setRoadCache2(
  value: ICarRoadCache,
  option?: Partial<{
    customizer: Function;
    road_cache_key: string;
    merge: boolean;
    full_key: string;
    level: number;
  }>,
) {
  const { customizer, road_cache_key, full_key, level } = option || {};

  let mergeWithLevel = 0;
  if (typeof level === 'number') {
    mergeWithLevel = level - 1;
  }

  const customizerInner = (
    sourceV: any,
    targetV: any,
    key: string,
    source: any,
    target: any,
    stack: { size: number },
  ) => {
    if (stack.size == mergeWithLevel) {
      return targetV || null;
    }
    // 常规merge with
    return customizer?.(sourceV, targetV, key, source, target, stack);
  };

  let merge = true;
  if (typeof option?.merge == 'boolean') {
    merge = option.merge;
  }
  return setLocalItem(
    full_key || getCacheKey(road_cache_key, undefined, option),
    value,
    Object.assign({ merge, customizer: customizerInner }, option || {}),
  );
}

/**
 * @deprecated 推荐使用更通用名称的 setRoadCache
 * @param value 设置缓存对象，对象采用 merge形式 ，使用 lodash.merge
 * @example
 * setCarRoadCache({ pdp: {idType:1111}})  // 最终数据为 { pdp: { idType:1111}}
 * setCarRoadCache({ quote: { carType:2222}}) //{ pdp: { idType:1111} , quote: { carType:2222}}
 * setCarRoadCache({pdp:{idType:111,list:[1]}},(objValue,srcValue)=>{
 * // deep 到这里，业务的处理方式，可以 contact objValue,srcValue，或者覆盖
 * if (Array.isArray(objValue)) {
    return srcValue;
  }
 * })
 */
export function setCarRoadCache(
  value: ICarRoadCache,
  customizer?: Function,
  road_cache_key?: string,
  option?: Record<string, any>,
) {
  return setRoadCache(value, customizer, road_cache_key, option);
}
/**
 *
 * @param key 读取链路缓存数据，为空，则读取所有的，可以指定key
 * @example
 * getRoadCache("pdp") //  获取 对象里面的 pdp 数据 ，{ pdp: { idType:1111}}
 * getRoadCache("pdp.xxx.postalCode") //  获取 对象里面的 pdp 对象下的 车牌号为 xxx ，{  pdp: {idType:1111}}
 */
export function getRoadCache(key?: string, road_cache_key?: string, option?: Record<string, any>) {
  return new Promise(async (resolve) => {
    const allItem = await getLocalItem(option?.full_key || getCacheKey(road_cache_key));
    // console.warn('storage key', getCacheKey());
    if (!key) {
      return resolve(allItem || {});
    }
    return resolve(get(allItem, key));
  });
}
/**
 * @deprecated 推荐使用更通用名称的 getRoadCache
 * @param key 读取链路缓存数据，为空，则读取所有的，可以指定key
 * @example
 * getCarRoadCache("pdp") //  获取 对象里面的 pdp 数据 ，{ pdp: { idType:1111}}
 * getCarRoadCache("pdp.xxx.postalCode") //  获取 对象里面的 pdp 对象下的 车牌号为 xxx ，{  pdp: {idType:1111}}
 */
export function getCarRoadCache(key?: string, road_cache_key?: string) {
  return getRoadCache(key, road_cache_key);
}
export function removeRoadCache(road_cache_key?: string) {
  return removeItem(getCacheKey(road_cache_key), 'local');
}
/**
 * 从 roadCache 中删除指定的对象
 * @param key 需要删除的key
 * @returns
 * @example
 * { quote: { carType:2222,a:1},pdp:{b:2}}
 * deleteRoadCacheByKey("quote")  ===> {b:1}
 * deleteRoadCacheByKey("quote.carType")  ===> {quote:{a:1},b:2}
 */
export function deleteRoadCacheByKey(key: string | string[], road_cache_key?: string) {
  return deleteByKey(getCacheKey(road_cache_key), key, 'local');
}
