import { IResponse } from '../common';

export const KYCBasicInfoForm = 'KYCBasicInfoForm';
export const KYCAdditionalInfoForm = 'KYCAdditionalInfoForm';

export const ID_TYPE_MAP = {
  umid: 'UMID',
  passport: 'Passport',
  driver_license: "Driver's License",
  prc_id: 'PRC',
  postal_id: 'Postal ID',
  birth_certificate: 'Birth Certificate',
  tin_id: 'TIN ID',
  philid: 'PHILID',
  health_id: 'Philhealth ID',
  senior_citizen_id: 'Senior Citizen ID',
  pwd_id: 'PWD ID',
  acr: 'ACR',
} as const;

export type IDTypeValue = keyof typeof ID_TYPE_MAP;
export type IDTypeLabel = (typeof ID_TYPE_MAP)[keyof typeof ID_TYPE_MAP];

export const GENDER_MAP = {
  male: 'Male',
  female: 'Female',
} as const;

export type IGenderValue = keyof typeof GENDER_MAP;
export type IGenderLabel = (typeof GENDER_MAP)[keyof typeof GENDER_MAP];

export const MARITAL_MAP = {
  single: 'Single',
  married: 'Married',
  widowed: 'Widowed',
  divorced: 'Divorced',
} as const;

export type IMaritalValue = keyof typeof MARITAL_MAP;
export type IMaritalLabel = (typeof MARITAL_MAP)[keyof typeof MARITAL_MAP];

export const EMPLOYMENT_MAP = {
  employed: 'Employed',
  self_employed: 'Self-employed',
  ph_unemployed_student: 'Student',
  ph_unemployed_retired: 'Retired',
  ph_unemployed_homemaker: 'Homemaker',
} as const;

export type IEmploymentValue = keyof typeof EMPLOYMENT_MAP;
export type IEmploymentLabel = (typeof EMPLOYMENT_MAP)[keyof typeof EMPLOYMENT_MAP];

export const USER_KYC_ACTION_MAP = {
  no_kyc_flow: 'no_kyc_flow',
  full_kyc_flow: 'full_kyc_flow',
  simplified_kyc_flow: 'simplified_kyc_flow',
} as const;

export type IUserKycActionValue = keyof typeof USER_KYC_ACTION_MAP;

export const SUCCESS_MAP = {
  yes: 'yes',
  no: 'no',
} as const;

export type ISuccessValue = keyof typeof SUCCESS_MAP;

export enum BasicInfoFormKey {
  IDType = 'certi_type',
  IDNumber = 'certi_no',
  Nationality = 'nationality',
  FirstName = 'first_name',
  MiddleName = 'middle_name',
  LastName = 'last_name',
  IDExpiryDate = 'certi_expire_date',
}

export enum AdditionInfoFormKey {
  Gender = 'gender',
  DateOfBirth = 'birthday',
  PlaceOfBirth = 'place_of_birth',
  Marital = 'marital_status',
  Phone = 'mobile',
  Address = 'address',
  DetailedAddress = 'detail',
  Employment = 'employment',
  Company = 'company_name',
  Email = 'email',
  FundSource = 'source_of_fund',
}

export type UploadOCRParams = {
  file_content: string;
  file_extension: string;
};

export type UploadOCRResponse = IResponse<{
  key: string;
}>;

export type GetOCRInfoParams = {
  front_photo_key: string;
  certi_type: IDTypeValue;
};

export type GetOCRInfoResponse = IResponse<{
  address: string;
  birthday: string;
  certi_expiry_date: string;
  certi_no: string;
  certi_type: string;
  first_name: string;
  gender: string;
  last_name: string;
  middle_name: string;
  place_of_birth: string;
}>;

export type Address = {
  zip_code?: string;
  country?: string;
  state?: string;
  city?: string;
  district?: string;
  town?: string;
  detail: string;
};

export type AccountInfo = {
  first_name: string;
  middle_name: string;
  last_name: string;
  certi_type: IDTypeValue;
  certi_no: string;
  certi_expire_date: string;
  mobile: string;
  mobile_country_code: string;
  address: Address;
  gender: IGenderValue;
  birthday: string;
  place_of_birth: string;
  marital_status: string;
  employment: IEmploymentValue;
  company_name: string;
  nationality: string;
  front_photo_key: string;
  back_photo_key?: string;
  liveness_photo_key?: string[];
  has_success_ocr: ISuccessValue;
  has_success_liveness_check: ISuccessValue;
  account_member_id?: string;
  real_name?: string;
};

export type UpdateAccountInfoParams = {
  account_info: AccountInfo;
  account_kyc_flow_action: IUserKycActionValue;
};

export type QueryAccountMemberDataParams = {
  account_member_id: string;
  no_sensitive?: boolean;
};

export type QueryAccountResponse = IResponse<{
  account_info: AccountInfo;
  account_member: AccountInfo;
}>;

export type SaveAccountMember = AccountInfo & { relationship: 'spouse' | 'parent' | 'child' };

export type SaveAccountMemberRequestParams = {
  account_member: SaveAccountMember;
};

export const PLACE_OF_BIRTH_DATA = [
  {
    label: 'BARMM',
    value: 'BARMM',
  },
  {
    label: 'Bicol Region',
    value: 'Bicol_Region',
  },
  {
    label: 'Cagayan Valley',
    value: 'Cagayan_Valley',
  },
  {
    label: 'Calabarzon',
    value: 'Calabarzon',
  },
  {
    label: 'Caraga Region',
    value: 'Caraga_Region',
  },
  {
    label: 'Central Luzon',
    value: 'Central_Luzon',
  },
  {
    label: 'Central Visayas',
    value: 'Central_Visayas',
  },
  {
    label: 'Cordillera Administrative Region',
    value: 'Cordillera_Administrative_Region',
  },
  {
    label: 'Davao Region',
    value: 'Davao_Region',
  },
  {
    label: 'Eastern Visayas',
    value: 'Eastern_Visayas',
  },
  {
    label: 'Ilocos Region',
    value: 'Ilocos_Region',
  },
  {
    label: 'MIMAROPA/ Southwestern Tagalog',
    value: 'MIMAROPA_Southwestern_Tagalog',
  },
  {
    label: 'National Capital Region',
    value: 'National_CapitalRegion',
  },
  {
    label: 'Northern Mindanao',
    value: 'Northern_Mindanao',
  },
  {
    label: 'Outside of The Philippines',
    value: 'Outside_The_Philippines',
  },
  {
    label: 'Soccsksargen',
    value: 'Soccsksargen',
  },
  {
    label: 'Western Visayas',
    value: 'Western_Visayas',
  },
  {
    label: 'Zamboanga Peninsula',
    value: 'Zamboanga_Peninsula',
  },
];
export const isBroker = location.host.indexOf('seainsurebroker') != -1;
export const privacyLink = isBroker
  ? 'https://shopee.ph/m/SIIB-PrivacyPolicy'
  : 'https://shopee.ph/m/SIIA-PrivacyPolicy';
export const tncLink = isBroker ? 'https://shopee.ph/m/SIIB-TOS' : 'https://shopee.ph/m/SIIA-TOS';

export enum FundSourceType {
  employed_fixed_income = 10,
  employed_variable_income = 11,
  self_employed_business_income = 12,
  remittances = 13,
  pension = 14,
  personal_savings_or_retirement_needs = 15,
  allowance = 16,
  inheritance = 17,
  investment_or_dividend_income = 18,
  rental_income = 19,
  sale_of_asset_or_property = 20,
  other_sources = 21,
}

export const FundSourceTypeEnumConst = {
  employed_fixed_income: 'Employed Fixed Income',
  employed_variable_income: 'Employed Variable Income',
  self_employed_business_income: 'Self Employed Business Income',
  remittances: 'Remittances',
  pension: 'Pension',
  personal_savings_or_retirement_needs: 'Personal Savings Or Retirement Needs',
  allowance: 'Allowance',
  inheritance: 'Inheritance',
  investment_or_dividend_income: 'Investment Or Dividend Income',
  rental_income: 'Rental Income',
  sale_of_asset_or_property: 'Sale Of Asset Or Property',
  other_sources: 'Other Sources',
};

export type IFundSourceValue = keyof typeof FundSourceTypeEnumConst;

export const FUNDSOURCE_DATA: { label: string; value: keyof typeof FundSourceType }[][] = [
  [
    {
      label: FundSourceTypeEnumConst.employed_fixed_income,
      value: 'employed_fixed_income',
    },
    {
      label: FundSourceTypeEnumConst.employed_variable_income,
      value: 'employed_variable_income',
    },
    {
      label: FundSourceTypeEnumConst.self_employed_business_income,
      value: 'self_employed_business_income',
    },
    {
      label: FundSourceTypeEnumConst.remittances,
      value: 'remittances',
    },
    { label: FundSourceTypeEnumConst.pension, value: 'pension' },
    {
      label: FundSourceTypeEnumConst.personal_savings_or_retirement_needs,
      value: 'personal_savings_or_retirement_needs',
    },
    { label: FundSourceTypeEnumConst.allowance, value: 'allowance' },
    {
      label: FundSourceTypeEnumConst.inheritance,
      value: 'inheritance',
    },
    {
      label: FundSourceTypeEnumConst.investment_or_dividend_income,
      value: 'investment_or_dividend_income',
    },
    {
      label: FundSourceTypeEnumConst.rental_income,
      value: 'rental_income',
    },
    {
      label: FundSourceTypeEnumConst.sale_of_asset_or_property,
      value: 'sale_of_asset_or_property',
    },
    {
      label: FundSourceTypeEnumConst.other_sources,
      value: 'other_sources',
    },
  ],
];
