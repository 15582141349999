import { PageTabValue, TabName, TabValue } from '../home/types';
import { parseQueryUrlToObject } from '@/module/url';
import './App.scss';
import { callNavigate } from '@/bridge';
import { isShopeePayApp } from '@/common/util';
import { IHomePageConfig } from '@/types/homepage';

export const handleHomeJump = (appRL: string, changeTab: Function) => {
  // appRL为空 不做操作
  if (!appRL) {
    return;
  }
  if (appRL.includes('/common/home.html')) {
    const urlParams = parseQueryUrlToObject(appRL) || {};
    // home tab跳转 使用changeTab方法
    const initTab = TabName[urlParams.tab as PageTabValue] || TabName.home;

    changeTab(initTab as TabValue, urlParams);
  } else {
    callNavigate({}, appRL);
  }
};

export function parseHomPageConfig(result?: IHomePageConfig) {
  if (process.env.REGION != 'my' || !isShopeePayApp || !result) return result;
  if (result.shopeePay) {
    result.bottom_text = result.shopeePay.bottom_text;
    result.shopeePay = undefined;
  }
  return result;
}
