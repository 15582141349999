import React, { useMemo, useEffect, useRef } from 'react';
import { callWebview } from '@/bridge';
import './index.scss';
import './quill.snow.scss';
import { trackClick } from '@/common/track';
import { listen, remove } from '@/module/event';
import { TAB_BAR_HEIGHT } from '@/common/eventKey';
import { scrollToAnimation } from '@/common/biz';
import xss from '@/common/xss';

interface ITnc {
  tnc: string;
  trackConfig?: {
    target_type?: string;
  };
  // 不能设置为 false ，如果有需要单独拿出来看
  xss?: boolean;
  className?: string;
}

function Tnc(props: ITnc) {
  const { tnc = '', trackConfig, className } = props;
  const containerRef = useRef<HTMLDivElement>(null);
  const tabbarHeight = useRef<number>(0);
  useEffect(() => {
    // 如果有tabbar，需要获取其高度
    const setTabbarHeight = (top: number) => {
      tabbarHeight.current = top;
    };
    listen(TAB_BAR_HEIGHT, setTabbarHeight);
    return () => {
      remove(TAB_BAR_HEIGHT, setTabbarHeight);
    };
  }, []);
  useEffect(() => {
    const curRef = containerRef.current;
    const clickLinkInterceptor = function (event: any) {
      // 兼容处理
      const target = (event.target as Element) || (event.srcElement as Element);
      // 判断是否匹配目标元素
      if (target?.nodeName.toLocaleLowerCase() === 'a') {
        // 对捕获到的 a 标签进行处理，需要先阻止冒泡
        event.stopPropagation();
        // 对捕获到的 a 标签进行处理，需要先禁止它的跳转行为
        if (event.preventDefault) {
          event.preventDefault();
        } else {
          event.returnValue = true;
        }
        // 处理完 a 标签的内容，重新触发跳转，根据原来 a 标签页 target 来判断是否需要新窗口打开
        const url = target.getAttribute('href') ?? '#';
        trackClick({
          target_type: target.getAttribute('data-target-type') || trackConfig?.target_type,
          data: {
            link: url,
            link_suffix: url.split('/m/'[1]),
          },
        });
        // 页内锚点
        if (/^#.+/.test(url)) {
          // 避免触发父元素的点击
          event.stopPropagation();

          const elem = document.querySelector(url) as HTMLElement;
          if (!elem) return;

          scrollToAnimation(elem.offsetTop - tabbarHeight.current);
          return;
        }
        // 外链
        callWebview({ url, type: 'external' });
      }
    };
    curRef?.addEventListener('click', clickLinkInterceptor);
    return () => curRef?.removeEventListener('click', clickLinkInterceptor);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return useMemo(() => {
    return (
      <div ref={containerRef} className={className}>
        {tnc && (
          <div className="ql-wrapper">
            <div
              dangerouslySetInnerHTML={{
                __html: props.xss === false ? tnc : xss(tnc),
              }}
              className="ql-editor"
            />
          </div>
        )}
      </div>
    );
    // NOTE 请注意memo的更新依赖
  }, [className, tnc, props.xss]);
}

export default Tnc;
