import React, { useMemo } from 'react';
import classNames from 'classnames';
import { ProductShelvesItem } from '@/types/common';
import { formatCurrencyV2 } from '@/common/biz';
import { handleNavigateToPdp } from '@/common/navigate';
import useMemoizedFn from '@/hooks/useMemoizeFn';
import { formatMsg } from '@/locales';
import LazyImage from '../LazyImage';
import './index.scss';
import { parseProductTags } from './help';

export interface IProductCard {
  product: ProductShelvesItem;
  onClick?: (product: ProductShelvesItem) => void;
  // 上报参数
  expoProps?: {
    'data-expo': boolean | string;
    'data-params': string;
  };
  card_layout?: 'rcmd-vertical' | 'rcmd-horizontal' | 'product-list';
  card_jump_handler?: (param: { product_id: string; product_main_type: string }) => void;
  showCustomMessage?: boolean;
}

export default function ProductCard(props: IProductCard) {
  const { product, onClick, expoProps, card_jump_handler, card_layout, showCustomMessage } = props;

  const {
    product_id,
    product_main_type,
    product_cover_image_url,
    product_display_name,
    key_selling_points,
    premium_display,
    product_tags,
    custom_message,
  } = product || {};

  const premiumDisplay = useMemo(() => {
    const {
      show_premium_type,
      premium_message,
      premium_before_discount,
      premium_after_discount,
      longest_tenure,
      longest_instalment_amount,
    } = premium_display || {};
    if (longest_tenure! > 1 && longest_instalment_amount) {
      return {
        longestTenure: longest_tenure,
        longestInstalmentAmount: longest_instalment_amount,
      };
    }
    if (show_premium_type == 'premium') {
      return {
        premiumAfterDiscount: premium_after_discount,
        premiumBeforeDiscount:
          premium_after_discount == premium_before_discount ? '' : premium_before_discount,
      };
    }
    return {
      premiumMessage: premium_message,
    };
  }, [premium_display]);

  const onCardClick = useMemoizedFn(() => {
    onClick?.(product);
    if (card_jump_handler) {
      card_jump_handler({ product_id, product_main_type });
      return;
    }
    handleNavigateToPdp({ product_id, product_main_type });
  });

  const {
    periodTags = [],
    promotionTags = [],
    installmentTag = [],
    maskTag,
    discountTag,
    flashDiscountTag,
    familyDiscountTag,
    voucherTag,
  } = parseProductTags(product_tags);

  const {
    premiumMessage,
    premiumAfterDiscount,
    premiumBeforeDiscount,
    longestTenure,
    longestInstalmentAmount,
  } = premiumDisplay;
  return useMemo(
    () => (
      <div className="product-card" onClick={onCardClick} {...expoProps}>
        {discountTag && (
          <div className="product-card__tag-discount">
            {discountTag}
            <br />
            {formatMsg('common.coupon.off')}
          </div>
        )}
        {flashDiscountTag && (
          <div className="product-card__tag-flash-discount">-{flashDiscountTag}</div>
        )}
        {maskTag && (
          <div
            className={classNames('product-card__tag-custom', {
              smaller: maskTag.length > 14,
            })}
          >
            {maskTag}
          </div>
        )}
        <div className={classNames('product-card__wrapper')}>
          <div className="product-card__info">
            <LazyImage className="product-card__cover" src={product_cover_image_url} />
            <div className="product-card__title">{product_display_name}</div>
            {premiumMessage ||
            premiumAfterDiscount ||
            premiumBeforeDiscount ||
            longestInstalmentAmount ? (
              <div className="product-card__premium">
                {premiumMessage && (
                  <span className="product-card__premium-after">{premiumMessage}</span>
                )}
                {premiumAfterDiscount || longestInstalmentAmount ? (
                  <>
                    <span className="product-card__premium-from">
                      {formatMsg('recommendedcard.from')}{' '}
                    </span>
                    <span className="product-card__premium-after">
                      {longestInstalmentAmount || formatCurrencyV2(premiumAfterDiscount)}
                    </span>
                  </>
                ) : null}
                {premiumBeforeDiscount ? (
                  <span className="product-card__premium-before">
                    {` ${formatCurrencyV2(premiumBeforeDiscount)}`}
                  </span>
                ) : null}
                {longestTenure && (
                  <>
                    <span className="product-card__premium-month">{`/${formatMsg('Month')}`}</span>
                    <span className="product-card__premium-tenure">{`x${longestTenure}`}</span>
                  </>
                )}
              </div>
            ) : null}
            <div className="product-card__tags">
              {/* Period → Family Discount → Cashback → Installment → Voucher */}
              {periodTags?.map((period, index) => (
                <div key={index} className="product-card__tag-period">
                  {period[0]} {period?.[1]}
                </div>
              ))}
              {familyDiscountTag && (
                <div className="product-card__tag-family">{familyDiscountTag}</div>
              )}
              {promotionTags.map((text, index) => {
                return (
                  <div className="product-card__tag-promotion" key={index}>
                    {text}
                  </div>
                );
              })}
              {installmentTag?.length ? (
                <div className="product-card__tag-installment">
                  {installmentTag[0] && (
                    <div className="product-card__tag-installment-left">{installmentTag[0]}</div>
                  )}
                  {installmentTag[1] && (
                    <div className="product-card__tag-installment-right">{installmentTag?.[1]}</div>
                  )}
                </div>
              ) : null}
              {voucherTag && <div className="product-card__tag-voucher">{voucherTag}</div>}
            </div>
          </div>
          {!!key_selling_points?.length && (
            <ul
              className={classNames('product-card__desc-list', {
                'product-card__desc-list__height':
                  window.INS_LOCALE == 'th' && card_layout == 'rcmd-horizontal',
              })}
            >
              {key_selling_points.map((desc, index) => (
                <li key={index} className="product-card__desc">
                  <div
                    className={classNames('product-card__desc-point', {
                      'product-card__desc-point-empty': !desc,
                    })}
                  />
                  <div className="product-card__desc-text">{desc}</div>
                </li>
              ))}
            </ul>
          )}
        </div>
        {showCustomMessage && custom_message?.need_displayed && custom_message?.message && (
          <div className="product-card__tip">{custom_message.message}</div>
        )}
      </div>
    ),
    [
      onCardClick,
      expoProps,
      discountTag,
      flashDiscountTag,
      familyDiscountTag,
      maskTag,
      product_cover_image_url,
      product_display_name,
      longestTenure,
      longestInstalmentAmount,
      premiumMessage,
      premiumAfterDiscount,
      premiumBeforeDiscount,
      installmentTag,
      voucherTag,
      promotionTags,
      periodTags,
      key_selling_points,
      showCustomMessage,
      custom_message,
      card_layout,
    ],
  );
}
